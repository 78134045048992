import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Spinner,
} from "reactstrap";
import withRouter from "../../Components/Common/withRouter";

const schedule = {
  pazartesi: [
    {
      subject: "Sosyal Bilgiler",
      start: "12:45",
      end: "13:15",
      isAvailable: true,
    },
    {
      subject: "Sosyal Bilgiler",
      start: "13:25",
      end: "13:55",
      isAvailable: true,
    },
    { subject: "Matematik", start: "14:05", end: "14:35", isAvailable: true },
    { subject: "Türkçe", start: "14:45", end: "15:15", isAvailable: true },
    { subject: "Türkçe", start: "15:25", end: "15:55", isAvailable: true },
    {
      subject: "Beden Eğitimi ve Spor",
      start: "16:05",
      end: "16:35",
      isAvailable: true,
    },
  ],
  salı: [
    {
      subject: "Din Kültürü ve Ahlak Bilgisi",
      start: "12:45",
      end: "13:15",
      isAvailable: false,
    },
    {
      subject: "Din Kültürü ve Ahlak Bilgisi",
      start: "13:25",
      end: "13:55",
      isAvailable: false,
    },
    { subject: "Matematik", start: "14:05", end: "14:35", isAvailable: true },
    { subject: "Türkçe", start: "14:45", end: "15:15", isAvailable: true },
    { subject: "Türkçe", start: "15:25", end: "15:55", isAvailable: true },
    {
      subject: "Beden Eğitimi ve Spor",
      start: "16:05",
      end: "16:35",
      isAvailable: true,
    },
  ],
  çarşamba: [
    {
      subject: "Fen Bilimleri",
      start: "12:45",
      end: "13:15",
      isAvailable: true,
    },
    { subject: "Türkçe", start: "13:25", end: "13:55", isAvailable: true },
    { subject: "Türkçe", start: "14:05", end: "14:35", isAvailable: true },
    { subject: "Matematik", start: "14:45", end: "15:15", isAvailable: true },
    {
      subject: "Görsel Sanatlar",
      start: "15:25",
      end: "15:55",
      isAvailable: true,
    },
    {
      subject: "Trafik Güvenliği",
      start: "16:05",
      end: "16:35",
      isAvailable: true,
    },
  ],
  perşembe: [
    {
      subject: "Sosyal Bilgiler",
      start: "12:45",
      end: "13:15",
      isAvailable: true,
    },
    { subject: "Türkçe", start: "13:25", end: "13:55", isAvailable: true },
    { subject: "Türkçe", start: "14:05", end: "14:35", isAvailable: true },
    { subject: "Matematik", start: "14:45", end: "15:15", isAvailable: true },
    {
      subject: "İnsan Hakları",
      start: "15:25",
      end: "15:55",
      isAvailable: true,
    },
    {
      subject: "İnsan Hakları",
      start: "16:05",
      end: "16:35",
      isAvailable: true,
    },
  ],
  cuma: [
    {
      subject: "Yabancı Dil",
      start: "12:45",
      end: "13:15",
      isAvailable: false,
    },
    {
      subject: "Yabancı Dil",
      start: "13:25",
      end: "13:55",
      isAvailable: false,
    },
    {
      subject: "Fen Bilimleri",
      start: "14:05",
      end: "14:35",
      isAvailable: true,
    },
    {
      subject: "Fen Bilimleri",
      start: "14:45",
      end: "15:15",
      isAvailable: true,
    },
    { subject: "Matematik", start: "15:25", end: "15:55", isAvailable: true },
    { subject: "Müzik", start: "16:05", end: "16:35", isAvailable: true },
  ],
};

const Home = () => {
  const [selectedDay, setSelectedDay] = useState(
    new Date().toLocaleString("tr-TR", { weekday: "long" }).toLowerCase()
  );
  const [lessons, setLessons] = useState(schedule[selectedDay]);

  const [loading, setLoading] = useState(false);
  const [upcomingLesson, setUpcomingLesson] = useState(null);

  useEffect(() => {
    const now = new Date();
    const currentHour = now.getHours();
    const currentMinute = now.getMinutes();
    const nowInMinutes = currentHour * 60 + currentMinute;

    const findNextLesson = () => {
      let nextLesson = null;

      for (const lesson of lessons) {
        const [startHour, startMinute] = lesson.start.split(":").map(Number);
        const lessonStartInMinutes = startHour * 60 + startMinute;

        if (nowInMinutes < lessonStartInMinutes) {
          // Eğer mevcut ders boşsa, geçerli bir ders seçiyoruz
          if (!lesson.isAvailable) {
            continue; // Boş dersleri atla
          }

          nextLesson = lesson;
          break; // Gelecek dersi bulduk, döngüyü bitir
        }
      }

      return nextLesson || null; // Gelecek bir ders yoksa null döndür
    };

    setUpcomingLesson(findNextLesson());

    const intervalId = setInterval(() => {
      setUpcomingLesson(findNextLesson());
    }, 60000); // Her dakika yaklaşan dersi güncelle

    return () => clearInterval(intervalId);
  }, [lessons]);

  const handleDayChange = (day) => {
    setLoading(true); // Yükleme durumunu başlat
    setSelectedDay(day);

    setTimeout(() => {
      setLessons(schedule[day]);
      setLoading(false); // Yükleme durumunu kapat
    }, 500); // 500 ms gecikme (gerçek bir yükleme simülasyonu için)
  };

  return (
    <div
      className="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100"
      style={{ background: "#FDFDFD" }}
    >
      <div className="auth-page-content overflow-hidden pt-lg-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6}>
              <div className="mb-5">
                <h1
                  className="display-2 coming-soon-text"
                  style={{ background: "lightpink", textAlign: "center" }}
                >
                  Coming Soon
                </h1>
              </div>
            </Col>
            <Col xs={12}>
              <Row className="justify-content-center">
                <Col md={8} lg={6}>
                  <div
                    className="w-100 mb-3 d-flex flex-wrap justify-content-center"
                    style={{ gap: "5px" }}
                  >
                    <Button
                      style={{
                        width: "90px",
                        borderColor: "#e63946",
                        backgroundColor:
                          selectedDay === "pazartesi" ? "#e63946" : "#f1faee",
                        color:
                          selectedDay === "pazartesi" ? "#f1faee" : "#e63946",
                      }}
                      onClick={() => handleDayChange("pazartesi")}
                    >
                      Pazartesi
                    </Button>
                    <Button
                      style={{
                        width: "90px",
                        borderColor: "#e63946",
                        backgroundColor:
                          selectedDay === "salı" ? "#e63946" : "#f1faee",
                        color: selectedDay === "salı" ? "#f1faee" : "#e63946",
                      }}
                      onClick={() => handleDayChange("salı")}
                    >
                      Salı
                    </Button>
                    <Button
                      style={{
                        width: "90px",
                        borderColor: "#e63946",
                        backgroundColor:
                          selectedDay === "çarşamba" ? "#e63946" : "#f1faee",
                        color:
                          selectedDay === "çarşamba" ? "#f1faee" : "#e63946",
                      }}
                      onClick={() => handleDayChange("çarşamba")}
                    >
                      Çarşamba
                    </Button>
                    <Button
                      style={{
                        width: "90px",
                        borderColor: "#e63946",
                        backgroundColor:
                          selectedDay === "perşembe" ? "#e63946" : "#f1faee",
                        color:
                          selectedDay === "perşembe" ? "#f1faee" : "#e63946",
                      }}
                      onClick={() => handleDayChange("perşembe")}
                    >
                      Perşembe
                    </Button>
                    <Button
                      style={{
                        width: "90px",
                        borderColor: "#e63946",
                        backgroundColor:
                          selectedDay === "cuma" ? "#e63946" : "#f1faee",
                        color: selectedDay === "cuma" ? "#f1faee" : "#e63946",
                      }}
                      onClick={() => handleDayChange("cuma")}
                    >
                      Cuma
                    </Button>
                  </div>

                  {loading ? (
                    <div className="text-center">
                      <Spinner style={{ color: "#e63946" }} className="mt-5" />
                      <p>Yükleniyor...</p>
                    </div>
                  ) : (
                    <div>
                      {lessons.map((lesson, index) => (
                        <Card
                          key={index}
                          style={{
                            marginBottom: "10px",
                            padding: "10px 20px",
                            backgroundColor: !lesson.isAvailable
                              ? "#8dc8d8"
                              : lesson === upcomingLesson
                              ? "#e63946"
                              : "white",
                            color: !lesson.isAvailable
                              ? "white"
                              : lesson === upcomingLesson
                              ? "white"
                              : "black",
                          }}
                        >
                          <h3
                            style={{
                              color: !lesson.isAvailable
                                ? "white"
                                : lesson === upcomingLesson
                                ? "white"
                                : "black",
                            }}
                          >
                            {lesson.subject}
                          </h3>
                          <p className="mb-0">
                            {lesson.start} - {lesson.end}
                          </p>
                        </Card>
                      ))}
                    </div>
                  )}
                </Col>
              </Row>
            </Col>
            <Col xs={12} className="mt-3">
              <Row className="justify-content-center">
                <Col md={8} lg={6}>
                  <div>
                    <div className="d-flex align-items-center mb-1">
                      <div
                        style={{
                          width: "30px",
                          height: "30px",
                          background: "#e63946",
                          marginRight: "3px",
                        }}
                      ></div>
                      <div>En yakın ders</div>
                    </div>
                    <div className="d-flex align-items-center">
                      <div
                        style={{
                          width: "30px",
                          height: "30px",
                          background: "#8dc8d8",
                          marginRight: "3px",
                        }}
                      ></div>
                      <div>Boş ders</div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>

      <footer className="footer">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="text-center">
                <p className="mb-0" style={{ color: "#808080" }}>
                  &copy; {new Date().getFullYear()}{" "}
                  <i className="mdi mdi-heart text-danger"></i> by Yusuf Ayaz
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </div>
  );
};

export default withRouter(Home);
