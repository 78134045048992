import { combineReducers } from "redux";

// Front
import LayoutReducer from "./other/layouts/reducer";

const rootReducer = combineReducers({
  Layout: LayoutReducer,
});

export default rootReducer;
