import React from "react";

import Home from "../pages/Home/Home";

const publicRoutes = [
  // Authentication Page
  { path: "/", component: <Home /> },
];

export { publicRoutes };
